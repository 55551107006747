import React from 'react'
import { Styled } from 'theme-ui'

import ThemeUIPrism from '@theme-ui/prism'
import PrismCore from 'prismjs/components/prism-core.min'
import 'prismjs/components/prism-markup.min'
import 'prismjs/components/prism-clike.min'
import 'prismjs/components/prism-javascript.min'
import 'prismjs/components/prism-bash.min'
import 'prismjs/components/prism-csv.min'
import 'prismjs/components/prism-diff.min'
import 'prismjs/components/prism-docker.min'
import 'prismjs/components/prism-git.min'
import 'prismjs/components/prism-groovy.min'
import 'prismjs/components/prism-http.min'
import 'prismjs/components/prism-ignore.min'
import 'prismjs/components/prism-java.min'
import 'prismjs/components/prism-json.min'
import 'prismjs/components/prism-json5.min'
import 'prismjs/components/prism-jsonp.min'
import 'prismjs/components/prism-jsstacktrace.min'
import 'prismjs/components/prism-kotlin.min'
import 'prismjs/components/prism-markdown.min'
import 'prismjs/components/prism-mongodb.min'
import 'prismjs/components/prism-properties.min'
import 'prismjs/components/prism-protobuf.min'
import 'prismjs/components/prism-jsx.min'
import 'prismjs/components/prism-tsx.min'
import 'prismjs/components/prism-typescript.min'
import 'prismjs/components/prism-uri.min'
import 'prismjs/components/prism-yaml.min'

const CodeBlock = props => (
    // eslint-disable-next-line react/jsx-pascal-case
    <ThemeUIPrism {...props} Prism={PrismCore} fallback={<Styled.pre>{props.children}</Styled.pre>} />
)

export default CodeBlock
